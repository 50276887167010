import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import resources from './locales';

const DEFAULT_LOCALE = 'zh-TW';
const languageDetector = new LanguageDetector();

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: DEFAULT_LOCALE, /* 當前語言的翻譯資源無法找到時，i18next 會嘗試回退到這個語言以提供預設的翻譯。 */
  });

export default i18n;
