
import styled from 'styled-components/macro';

export const Background = styled.div`
  background-color: ${props => props.theme.color.green.tint};
  width: 100%;
  height: 100vh;
  min-height: 850px;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  @media
  ${props => props.theme.device.mobile},
  ${props => props.theme.device.smMobile} {
    min-height: 100vh;
  }
`;

export const LoginBox = styled.div`
  position: relative;
  margin-top: 144px;
  background-color: #FFFFFF;
  width: 100%;
  max-width: 540px;
  height: 648px;
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  padding: 60px 80px 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media
  ${props => props.theme.device.mobile},
  ${props => props.theme.device.smMobile} {
    margin: 0px;
    height: 100vh;
    max-width: 100vw;
    border-radius: 0px;
  }
  @media ${props => props.theme.device.mobile} {
    padding: 100px 28px 30px;
  }
  @media ${props => props.theme.device.smMobile} {
    padding-top: 60px 20px 20px;
  }
`;

export const Title = styled.h2`
  font-size: 34px;
  font-weight: 700;
  margin: 0px;
`;

export const LoginDescription = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: ${props => props.theme.color.textColor};
`;

export const Logo = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const LogoBox = styled.div`
  width: fit-content;
  min-width: 96px;
  height: 96px;
  border: 1px solid ${props => props.theme.color.green.light};
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Form = styled.form`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HelperText = styled.span`
  display: flex;
  align-items: center;
  font-size: 18px;
  svg {
    margin-right: 4px;
  }
`;

export const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TextButton = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
  color: ${props => props.theme.color.green.primary};
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.color.green.dark};
  }
`;

export const CreateAccount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Info = styled.div`
  display: flex;
  align-items: flex-start;
  height: 100%;
  svg {
    margin-top: 2px;
    margin-right: 4px;
    flex: 0 0 20px;
  }
`;

export const InfoContainer = styled.div`
  position: absolute;
  right: 0px;
  bottom: 0px;
  transform: translateY(calc(100% + 12px));
  @media
  ${props => props.theme.device.mobile},
  ${props => props.theme.device.smMobile} {
    width: fit-content;
    position: relative;
    margin-top: 60px;
    transform: none;
  }
`;