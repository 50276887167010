import React from 'react';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { Route, Routes } from 'react-router-dom';

import { defaultTheme, darkTheme } from 'src/themes';
import { routePathConfig } from 'src/routes/config';
import Login from 'src/features/login/pages';
import Home from 'src/features/home/pages';
import Error from 'src/features/error/pages';
import GrantConsent from 'src/features/grantConsent/pages';
import { pittaTheme } from 'src/themes/muiTheme';

function App() {
  const [isDarkMode] = React.useState(false);

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : defaultTheme}>
      <MuiThemeProvider theme={pittaTheme}>
        <Routes>
          <Route path={routePathConfig.login} element={<Login />} />
          <Route path={routePathConfig.grantConsent} element={<GrantConsent />} />
          <Route path={routePathConfig.error} element={<Error />} />
          <Route path={`${routePathConfig.home}*`} element={<Home />} />
        </Routes>
      </MuiThemeProvider>
    </ThemeProvider>
  );
}

export default App;
