import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from 'src/store/hooks';
import { initLogin, selectLogin } from 'src/features/login/slice';
import {
  initGrantConsent,
  getGrantedConsentsCall, putGrantedConsentsCall, postGenerateAuthorizationCodeCall,
  selectGrantConsent } from 'src/features/grantConsent/slice';
import { routePathConfig } from 'src/routes/config';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from 'src/components/Button';
import { CircularProgress } from '@mui/material';
import PittaLogoWithTextSVG from 'src/assets/images/PittaLogoWithText.svg';
import { getAuthorizes, removeAuthorize } from 'src/storage/authorize';
import { camelToSnake } from 'src/utils/common';
import { ApiInvoked } from 'src/constants';

import { Background, Box, Logo, LogoBox, ClientTitle, Description, ClientRequestedConsents } from '../styled';

const GrantConsent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    isUserValid,
    queryClient,
    postAuthorizeUser,
  } = useAppSelector(selectLogin);
  const {
    queryConsent,
    putGrantedConsents,
    grantAuthCode,
  } = useAppSelector(selectGrantConsent);
  const navigate = useNavigate();
  const [selectedConsents, setSelectedConsents] = React.useState<string[]>([]);
  const userVerificationPath = `${routePathConfig.login}?client_id=${queryClient.data.client.clientId}&redirect_uri=${queryClient.data.client.redirectUri}`;

  const handleSubmitGrantConsent = () => {
    dispatch(putGrantedConsentsCall({
      clientId: queryClient.data.client.clientId,
      redirect_uri: queryClient.data.client.redirectUri,
      consents: selectedConsents
    }));
  };

  const handleOnCancel = () => {
    const foundAuthorize = getAuthorizes()[0];
    removeAuthorize(foundAuthorize.account);
    dispatch(initGrantConsent());
    dispatch(initLogin());
    navigate(userVerificationPath);
  };

  React.useEffect(() => {
    if (!isUserValid) {
      removeAuthorize(postAuthorizeUser.data.user.account);
      dispatch(initGrantConsent());
      dispatch(initLogin());
      navigate(userVerificationPath);
      return;
    }
    if (isUserValid) {
      dispatch(getGrantedConsentsCall({
        clientId: queryClient.data.client.clientId,
        locale: queryClient.data.client.locale,
      }));
    }
  }, [isUserValid]);

  React.useEffect(() => {
    if (queryConsent.status === ApiInvoked.DONE) {
      setSelectedConsents(queryConsent.data.userGrantedConsents);
    }
  }, [queryConsent.status]);

  React.useEffect(() => {
    if (putGrantedConsents.status === ApiInvoked.DONE) {
      // Request AuthCode w/ UserPass
      dispatch(postGenerateAuthorizationCodeCall(camelToSnake({
        clientId: queryClient.data.client.clientId,
        redirectUri:  queryClient.data.client.redirectUri,
      })));
    }
  }, [putGrantedConsents.status]);

  React.useEffect(() => {
    if (grantAuthCode.status === ApiInvoked.DONE) {
      window.location.href = `${queryClient.data.client.redirectUri}?autoCode=${grantAuthCode.data.authCode}`; // Redirect to Client Callback URL w/ AuthCode
    }
    if (grantAuthCode.status === ApiInvoked.FAILED) {
      removeAuthorize(postAuthorizeUser.data.user.account);
      dispatch(initGrantConsent());
      dispatch(initLogin());
      navigate(userVerificationPath);
    }
  }, [grantAuthCode.status]);

  return (
    <Background>
      <Box>
        <Logo>
          <LogoBox>
            <img src={queryClient.data.client.logo} height={40} style={{ padding: '0px 8px' }} />
          </LogoBox>
        </Logo>
        <ClientTitle>
          {queryClient.data.client.title}
        </ClientTitle>
        <Description>
          {t('grant_consent:request_access_the_following_items')}
        </Description>
        <ClientRequestedConsents>
          {queryConsent.data.clientRequestedConsents.flatMap((category) =>
            category.consents.map((consent) => (
              <FormControlLabel
                key={consent.consentKey}
                control={<Checkbox checked={selectedConsents.includes(consent.consentKey)} />}
                label={consent.title}
                disabled={consent.isMandatory}
                onChange={() => {
                  const isChecked = selectedConsents.includes(consent.consentKey);
                  if (isChecked) {
                    const newList = selectedConsents.filter((item) => item !== consent.consentKey);
                    setSelectedConsents(newList);
                  } else {
                    setSelectedConsents([...selectedConsents, consent.consentKey]);
                  }
                }}
              />
            )))}
        </ClientRequestedConsents>
        <Button
          variant="contained"
          sx={{ marginTop: '40px', width: '100%', textTransform: 'none', fontWeight: 700, whiteSpace: 'nowrap' }}
          disabled={putGrantedConsents.status === ApiInvoked.LOADING}
          onClick={handleSubmitGrantConsent}
        >
          {putGrantedConsents.status === ApiInvoked.LOADING && <CircularProgress size={24} sx={{ color: '#FFF', marginRight: '8px' }} />}
          <img src={PittaLogoWithTextSVG} alt="PittaLogoWithTextSVG" style={{ marginRight: '12px' }} />
          {t('grant_consent:continue_with_pitta')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{ marginTop: '8px', width: '100%', fontWeight: 700 }}
          disabled={false}
          onClick={handleOnCancel}
        >
          {t('common:cancel')}
        </Button>
      </Box>
    </Background>
  );
};

export default GrantConsent;
