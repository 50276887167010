import * as React from 'react';
import i18next from 'i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { queryClientCall, selectLogin } from 'src/features/login/slice';
import { routePathConfig } from 'src/routes/config';
import { ApiInvoked } from 'src/constants';

export const useClientVerification = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const clientId = searchParams.get('client_id');
  const redirectUri = searchParams.get('redirect_uri');
  const locale = searchParams.get('locale') || i18next.language;
  const {
    queryClient
  } = useAppSelector(selectLogin);

  React.useEffect(() => {
    const hasClientParameters = clientId && redirectUri;
    if (!hasClientParameters) {
      navigate(routePathConfig.error);
      return;
    }
    dispatch(queryClientCall({
      clientId, redirectUri, locale
    }));
  }, []);

  React.useEffect(() => {
    if (queryClient.status === ApiInvoked.FAILED) {
      navigate(routePathConfig.error);
    }
  }, [queryClient.status]);

  React.useEffect(() => {
    i18next.changeLanguage(queryClient?.data?.client?.locale || i18next.language);
  }, [locale]);

  return;
};
