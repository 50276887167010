import * as React from 'react';
import { useTranslation } from 'react-i18next';

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { CircularProgress } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import PittaLogoSVG from 'src/assets/images/PittaLogo.svg';
import TextField from 'src/components/TextField';
import Button from 'src/components/Button';
import { Person, Lock, Error, Help } from 'src/components/Icon';
import { postAuthorizeUserCall, selectLogin } from 'src/features/login/slice';
import gradientArrowsSVG from 'src/assets/images/gradientArrows.svg';
import { useClientVerification } from 'src/features/login/hooks/useClientVerification';
import { useCheckUserPassInLocalStorage } from 'src/features/login/hooks/useCheckUserPassInLocalStorage';
import { getPittaRem } from 'src/utils/cookie';
import { ApiInvoked } from 'src/constants';

import {
  Info, Background, LoginBox, Title, LoginDescription, Logo, LogoBox, Form, HelperText, FlexBetween, TextButton,
  CreateAccount, InfoContainer
} from '../styled';


const Login = () => {
  useClientVerification();
  useCheckUserPassInLocalStorage();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = React.useState(false);
  const [account, setAccount] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [remember, setRemember] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const {
    queryClient, postAuthorizeUser
  } = useAppSelector(selectLogin);
  const { client } = queryClient.data;

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSubmit = () => {
    if (!account || !password) {
      return;
    }
    dispatch(postAuthorizeUserCall({
      account: account.trim(),
      password: password.trim(),
      remember_me: remember
    }));
  };

  React.useEffect(() => {
    const pittaRem = getPittaRem();
    if (pittaRem?.account) {
      setAccount(pittaRem.account);
      setRemember(true);
    }
  }, []);

  React.useEffect(() => {
    if (postAuthorizeUser.status === ApiInvoked.FAILED) {
      if (['Wrong Login Parameters', 'Wrong User Or Invalid User Credentials'].includes(postAuthorizeUser.error?.message || '')) {
        setErrorMsg(t('error_message:incorrect_account_or_password'));
        return;
      }
      if (postAuthorizeUser.error?.message) {
        setErrorMsg(postAuthorizeUser.error?.message);
        return;
      }
    }
  }, [postAuthorizeUser.error?.error, postAuthorizeUser.status]);

  return (
    <Background>
      <LoginBox>
        <Title>{client.logo ? t('login:pitta_account_verification') : t('login:login')}</Title>
        {client.title &&
          <LoginDescription>
            <span style={{ marginRight: '4px' }}>{t('login:sign_in_with_pitta_account')}</span>
            <TextButton
              onClick={() => {
                window.open(client.homeUri);
              }}
            >
              {client.title}
            </TextButton>
          </LoginDescription>
        }
        <Logo>
          <LogoBox>
            <img src={PittaLogoSVG} height={96} alt="Pitta Logo" />
          </LogoBox>
          {
            !!client.logo &&
            <>
              <img src={gradientArrowsSVG} alt="gradientArrowsSVG" />
              <LogoBox>
                <img src={client.logo} height={40} style={{ padding: '0px 8px' }} />
              </LogoBox>
            </>
          }
        </Logo>
        {
          queryClient.status !== ApiInvoked.DONE &&
          <div style={{ marginTop: '80px' }}>
            <CircularProgress />
          </div>
        }
        {queryClient.status === ApiInvoked.DONE &&
          <Form>
            <TextField
              placeholder={t('login:account')}
              autoComplete="username"
              value={account}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setErrorMsg('');
                setAccount(event.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Person />
                  </InputAdornment>
                ),
              }}
              error={!!errorMsg}
            />
            <TextField
              placeholder={t('login:password')}
              autoComplete="current-password"
              value={password}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setErrorMsg('');
                setPassword(event.target.value);
              }}
              type={showPassword ? 'text' : 'password'}
              sx={{ marginTop: '12px' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ color: '#2d2d2d' }}
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={!!errorMsg}
              helperText={
                !!errorMsg && (
                  <HelperText>
                    <Error />
                    {errorMsg}
                  </HelperText>
                )}
            />
            <Button
              variant="contained"
              sx={{ marginTop: '40px' }}
              disabled={postAuthorizeUser.status === ApiInvoked.LOADING}
              onClick={handleSubmit}
            >
              {postAuthorizeUser.status === ApiInvoked.LOADING && <CircularProgress size={24} sx={{ color: '#FFF', marginRight: '8px' }} />}
              {t('login:login')}
            </Button>
            <FlexBetween>
              <FormControlLabel
                control={<Checkbox checked={remember} />}
                label={t('login:remember_me')}
                sx={{ transform: 'translateY(1px)' }}
                onChange={() => setRemember(!remember)}
              />
              <TextButton>{t('login:forget_password')}</TextButton>
            </FlexBetween>
            <CreateAccount>
              {t('login:without_account')}<TextButton>{t('login:create_account')}</TextButton>
            </CreateAccount>
          </Form>
        }
        <InfoContainer>
          <Info>
            <Help />{t('login:contact_the_person')}
          </Info>
        </InfoContainer>
      </LoginBox>
    </Background>
  );
};

export default Login;
