import { createSlice, createAction } from '@reduxjs/toolkit';
import { throttleTime, switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of, from } from 'rxjs';

import { RootState, AppEpic } from 'src/store/types';
import { getIdentityClient, postAuthorizeUser } from 'src/api/services';
import { snakeToCamel } from 'src/utils/common';
import { setPittaRem } from 'src/utils/cookie';
import { ApiInvoked } from 'src/constants';
import { TApiInvokedStatus } from 'src/types';

export interface IState {
  isUserValid: boolean;
  queryClient: {
    data: {
      tokenType: string;
      tokenSubject: string;
      expiresIn: number;
      csrfCode: string;
      client: {
        clientId: string;
        redirectUri: string;
        homeUri: string;
        status: string;
        title: string;
        description: string;
        ranking: string;
        logo: string;
        locale: string;
      }
    },
    status: TApiInvokedStatus;
    error?: {
      error: string;
      message: string;
    }
  },
  postAuthorizeUser: {
    data: {
      tokenType: string;
      tokenSubject: string;
      expiresIn: number;
      userPass: string;
      user: {
        account: string;
        status: string;
        name: string;
        avatar: string;
        ownerType: string;
      }
    },
    status: TApiInvokedStatus;
    error?: {
      error: string;
      message: string;
    }
  },
}

const initialState: IState = {
  isUserValid: false,
  queryClient: {
    data: {
      tokenType: '',
      tokenSubject: '',
      expiresIn: 0,
      csrfCode: '',
      client: {
        clientId: '',
        redirectUri: '',
        homeUri: '',
        status: '',
        title: '',
        description: '',
        ranking: '',
        logo: '',
        locale: '',
      }
    },
    status: ApiInvoked.IDLE,
    error: undefined,
  },
  postAuthorizeUser: {
    data: {
      tokenType: '',
      tokenSubject: '',
      expiresIn: 0,
      userPass: '',
      user: {
        account: '',
        status: '',
        name: '',
        avatar: '',
        ownerType: '',
      }
    },
    status: ApiInvoked.IDLE,
    error: undefined,
  },
};

export const slice = createSlice({
  name: 'login',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    initLogin: (state) => {
      state.isUserValid = false,
      state.queryClient = initialState.queryClient;
      state.postAuthorizeUser = initialState.postAuthorizeUser;
    },
    userVerification: (state) => {
      state.isUserValid = true;
    },
    queryClientCall: (state) => {
      state.queryClient = {
        ...initialState.queryClient,
        status: ApiInvoked.LOADING,
      };
    },
    queryClientDone: (state, { payload }) => {
      state.queryClient.data = payload;
      state.queryClient.status = ApiInvoked.DONE;
    },
    queryClientFailed: (state, { payload }) => {
      state.queryClient.status = ApiInvoked.FAILED;
      state.queryClient.error = payload;
    },
    postAuthorizeUserCall: (state) => {
      state.postAuthorizeUser = {
        ...initialState.postAuthorizeUser,
        status: ApiInvoked.LOADING,
      };
    },
    postAuthorizeUserDone: (state, { payload }) => {
      state.postAuthorizeUser.data = payload;
      state.isUserValid = true;
      state.postAuthorizeUser.status = ApiInvoked.DONE;
    },
    postAuthorizeUserFailed: (state, { payload }) => {
      state.postAuthorizeUser.error = payload;
      state.postAuthorizeUser.status = ApiInvoked.FAILED;
    }
  },
});

export default slice.reducer;

/**
 *  Selectors
 */
export const selectLogin = (state: RootState) => state.login;

/**
 *  Actions
 */
export const queryClientCall = createAction<object>('login/queryClientCall');
export const postAuthorizeUserCall = createAction<object>('login/postAuthorizeUserCall');
export const { initLogin, userVerification, queryClientDone, queryClientFailed, postAuthorizeUserDone, postAuthorizeUserFailed } = slice.actions;

/**
 *  Epics
 */
export const getIdentityClientEpic: AppEpic = (action$) => action$.pipe(
  ofType(queryClientCall.type),
  throttleTime(500),
  switchMap(({ payload = {} }) => {
    return from(getIdentityClient(payload)).pipe(
      switchMap(response => {
        const responseData = snakeToCamel(response.data);
        return of(queryClientDone(responseData));
      }),
      catchError((error) => of(queryClientFailed(error.response.data)))
    );
  })
);

export const postAuthorizeUserEpic: AppEpic = (action$) => action$.pipe(
  ofType(postAuthorizeUserCall.type),
  throttleTime(500),
  switchMap(({ payload = {} }) => {
    return from(postAuthorizeUser(payload)).pipe(
      switchMap(response => {
        const responseData = snakeToCamel(response.data) as {
          tokenType: string;
          tokenSubject: string;
          expiresIn: number;
          userPass: string;
          user: {
            account: string;
            status: string;
            name: string;
            avatar: string;
            ownerType: string;
          }
        };
        if (payload.remember_me) {
          const rem = {
            account: payload.account,
            expiresIn: responseData.expiresIn,
          };
          setPittaRem(rem);
        }
        return of(postAuthorizeUserDone(responseData));
      }),
      catchError((error) => of(postAuthorizeUserFailed(error.response.data)))
    );
  })
);


export const epics = [
  getIdentityClientEpic,
  postAuthorizeUserEpic
];
